import React from "react"
import Layout from "../components/layout"
import { Seo } from "../components/Seo"

const cookie = () => {
  return (
    <Layout>
      <div className="ui-body-container products">
        <h1 className="body-title product-title">
          Technical & Organisational Security Measures
        </h1>
        <div className="spacing-divider--extra-small"></div>
        <div className="ui-body__text-image products">
          <div className="ui-body__text-image--left product-content">
            <p>
              At a minimum, Intelligence Analytics has implemented technical and
              organisational measures and maintains security practices as
              follows:
            </p>

            <h2 className="body-title">Identity and Access Management</h2>

            <p>
              Predefined security groups are utilized to assign role-based
              access privileges and segregate access to data to the production
              systems.
            </p>

            <p>
              Administrator access to the production systems is granted based on
              job roles and responsibilities and limited to authorized
              personnel.
            </p>

            <p>
              We operate a strict Zero Trust policy across all applications and
              all employees to ensure security groups are enforced and
              authorized personnel are provided the correct level of access.
            </p>

            <h2 className="body-title">
              Audit Assurance: Compliance, Governance and Risk Management
            </h2>

            <p>
              Intelligence Analytics performs annual security operational risk
              assessments of production applications and services. Results from
              risk assessment activities are documented in a risk register and
              prioritized for treatment based on risk level.
            </p>

            <p>
              Intelligence Analytics performs a vendor security review for
              third-party vendors whose services will store, process or transmit
              Intelligence Analytics’ data and/or Intelligence Analytics’
              customer data.
            </p>

            <p>
              Intelligence Analytics performs risk-based continuous control
              monitoring throughout the year by performing control testing using
              a formal methodology. The testing results are documented and
              reviewed by management, including remediation plans for identified
              observations.
            </p>

            <p>
              Controlled documents are reviewed, approved by management, and
              communicated to relevant employees annually.
            </p>

            <p>
              Identifiable user data is Pseudonymised using randomly generated
              hash codes.
            </p>

            <h2 className="body-title">Human Resources</h2>

            <p>
              Intelligence Analytics team members complete security awareness
              training upon hire and annually thereafter. The training includes
              relevant Intelligence Analyticssecurity policies, instructions for
              reporting security incidents and general industry security best
              practices.
            </p>

            <p>
              Intelligence Analytics new hires are required to pass a background
              check as a condition of their employment.
            </p>

            <div className="spacing-divider--extra-small"></div>

            <h1 className="body-title">Integrity of processing systems</h1>

            <h2 className="body-title">Application & Infrastructure Security</h2>

            <p>
              Infrastructure management and configuration management tools are
              used for security hardening and to ensure baseline configuration
              standards have been established for production servers.
            </p>

            <p>
              Network traffic to and from untrusted networks passes through a
              policy enforcement point; firewall rules are configured to prevent
              unauthorized access.
            </p>

            <p>
              An issue tracking system is in place to centrally maintain,
              manage, and monitor application and infrastructure changes from
              development through implementation.
            </p>

            <p>
              Public IP addresses of machines and applications are not visible,
              access is permitted only over encrypted tunnels using the
              Wireguard protocol and proxied to hide the real IP address.
            </p>

            <p>
              Public and Private websites accept connections with minimum TLS
              version 2 and are only accessible via HTTPS encrypted connections.
            </p>

            <h2 className="body-title">Threat and Vulnerability Management</h2>

            <p>
              Intelligence Analytics executes a 3rd party application
              penetration test on an annual basis, a process which includes
              additional 3rd party remediation testing if any high or moderate
              risk vulnerabilities are identified.
            </p>

            <p>
              Monitoring tools are used to continuously monitor security events,
              latency, network performance, and virtual server performance.
            </p>

            <p>
              Incident response procedures are in place that outline the
              response procedures to security events and includes lessons
              learned to evaluate the effectiveness of the procedures.
            </p>

            <p>
              We implement best-in-class DDOS and bot protection on all public
              and private applications and have the ability to remain online
              even in the event of an attack with minimal disruption.
            </p>

            <div className="spacing-divider--extra-small"></div>

            <h1 className="body-title">Availability of processing systems</h1>

            <h2 className="body-title">Resilience</h2>

            <p>
              A business continuity plan is in place to guide personnel in
              procedures to protect against disruptions caused by an unexpected
              event. Tabletop exercises are completed on an annual basis.
            </p>

            <p>
              Hourly snapshots of application data are taken and stored for 7
              days in case of short-term data loss.
            </p>

            <p>
              Continuous daily backups offsite protect from long-term data loss
              and data loss in event of device failure, fire or other means
              where the application data would be otherwise completely
              destroyed.
            </p>

            <p>
              Company laptops and computers are backed up hourly to protect very
              short-term local data loss.
            </p>

            <div className="spacing-divider--extra-small"></div>

            <h1 className="body-title">Additional Considerations</h1>

            <p>
              AWS, Cloudflare and Microsoft Azure are responsible for
              implementing controls to manage physical and logical access to the
              servers and supporting infrastructure, underlying network and
              virtualization management software for its cloud hosting services
              where Intelligence Analytics processing systems reside.
            </p>

            <p>Updated 15/05/2022</p>

            <p>
              <strong className="strong-white">
                All rights reserved Intelligence Analytics Limited
              </strong>
            </p>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default cookie
export const Head = () => <Seo />
